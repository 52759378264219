// import { ConsolePage } from './pages/ConsolePage';
import './App.scss';
import { ConsolePage } from './pages/ConsolePage';
import { MciPage } from './pages/MciPage';

function App() {
  return (
    <div data-component="App">
      {/* <ConsolePage /> */}
      <MciPage />
    </div>
  );
}

export default App;
