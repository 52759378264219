/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.3 -t public/models/Parsian_Character.glb 
*/

import * as THREE from 'three';
import React, { useEffect } from 'react';
import { useGraph } from '@react-three/fiber';
import { useGLTF, useAnimations } from '@react-three/drei';
import { GLTF, SkeletonUtils } from 'three-stdlib';
import { useAtom } from 'jotai';
import { characterCurrActionAtom } from '../../store';

type ActionName =
  | 'Breath'
  | 'Idle'
  | 'Listen_body'
  | 'talk_Body'
  | 'Think'
  | 'Listen_Key_1'
  | 'Talk_Key_1'
  | 'Listen_Key_2'
  | 'Talk_Key_2'
  | 'Listen_Key_3'
  | 'Talk_Key_3'
  | 'Listen_Key_4'
  | 'Talk_Key_4'
  | 'Listen_Key_5'
  | 'Talk_Key_5'
  | 'Listen_Key_6'
  | 'Talk_Key_6'
  | 'Listen_Key_7'
  | 'Talk_Key_7'
  | 'Armature|2508256545808_TempMotion';

interface GLTFAction extends THREE.AnimationClip {
  name: ActionName;
}

type GLTFResult = GLTF & {
  nodes: {
    CC_Base_Body_1: THREE.SkinnedMesh;
    CC_Base_Body_2: THREE.SkinnedMesh;
    CC_Base_Body_3: THREE.SkinnedMesh;
    CC_Base_Body_4: THREE.SkinnedMesh;
    CC_Base_Body_5: THREE.SkinnedMesh;
    CC_Base_Eye_1: THREE.SkinnedMesh;
    CC_Base_Eye_2: THREE.SkinnedMesh;
    CC_Base_Eye_3: THREE.SkinnedMesh;
    CC_Base_Eye_4: THREE.SkinnedMesh;
    CC_Base_EyeOcclusion_1: THREE.SkinnedMesh;
    CC_Base_EyeOcclusion_2: THREE.SkinnedMesh;
    CC_Base_TearLine_1: THREE.SkinnedMesh;
    CC_Base_TearLine_2: THREE.SkinnedMesh;
    CC_Base_Teeth_1: THREE.SkinnedMesh;
    CC_Base_Teeth_2: THREE.SkinnedMesh;
    Classic_short_1: THREE.SkinnedMesh;
    CC_Base_Tongue: THREE.SkinnedMesh;
    Business_Shirt: THREE.SkinnedMesh;
    Business_Suit: THREE.SkinnedMesh;
    Pants: THREE.SkinnedMesh;
    Short_cowboy_boots: THREE.SkinnedMesh;
    svgMesh1: THREE.SkinnedMesh;
    Backdrop_01_1: THREE.Mesh;
    CC_Base_BoneRoot: THREE.Bone;
  };
  materials: {
    Std_Skin_Body: THREE.MeshStandardMaterial;
    Std_Skin_Arm: THREE.MeshStandardMaterial;
    Std_Nails: THREE.MeshStandardMaterial;
    Std_Eyelash: THREE.MeshStandardMaterial;
    Std_Eye_R: THREE.MeshStandardMaterial;
    Std_Cornea_R: THREE.MeshStandardMaterial;
    Std_Eye_L: THREE.MeshStandardMaterial;
    Std_Cornea_L: THREE.MeshStandardMaterial;
    Std_Eye_Occlusion_R: THREE.MeshStandardMaterial;
    Std_Eye_Occlusion_L: THREE.MeshStandardMaterial;
    Std_Tearline_R: THREE.MeshStandardMaterial;
    Std_Tearline_L: THREE.MeshStandardMaterial;
    Std_Upper_Teeth: THREE.MeshStandardMaterial;
    Std_Lower_Teeth: THREE.MeshStandardMaterial;
    Std_Tongue: THREE.MeshStandardMaterial;
    Short_cowboy_boots: THREE.MeshStandardMaterial;
    _: THREE.MeshStandardMaterial;
    Backdrop_01: THREE.MeshStandardMaterial;
  };
  animations: GLTFAction[];
};

export function ParsianCharacter(props: JSX.IntrinsicElements['group']) {
  const group = React.useRef<THREE.Group>(null);
  const { scene, animations } = useGLTF('models/Parsian_Character.glb');
  const clone = React.useMemo(() => SkeletonUtils.clone(scene), [scene]);
  const { nodes, materials } = useGraph(clone) as GLTFResult;
  const { actions } = useAnimations(animations, group);
  const [characterCurrAction] = useAtom(characterCurrActionAtom);

  let talkAnimations = [
    'talk_Body',
  ];
  let listenAnimations = ['Listen_body'];
  let idleAnimations = ['Idle'];
  function getAnimations(action: string) {
    let animations: string[] = [];
    if (action == 'Idle') {
      animations = idleAnimations;
    } else if (action == 'Explain') {
      animations = talkAnimations;
    } else if (action == 'Listen') {
      animations = listenAnimations;
    }
    return animations;
  }
  useEffect(() => {
    let animations = getAnimations(characterCurrAction);

    animations.forEach((animation) => {
      console.log('fadeIn', animation);
      actions[animation]?.reset().fadeIn(0.4).play();
    });

    return () => {
      let animations = getAnimations(characterCurrAction);
      animations.forEach((animation) => {
        console.log('fadeOut', animation);
        actions[animation]?.fadeOut(0.4);
      });
    };
  }, [characterCurrAction]);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="Bank_Characterglb">
          <group name="scene_(5)glb">
            <group name="Parsian_Character_with_stageglb">
              <group name="Parsian_Character_Base" scale={0.01}>
                <primitive object={nodes.CC_Base_BoneRoot} />
                <group name="CC_Base_Body">
                  <skinnedMesh
                    name="CC_Base_Body_1"
                    geometry={nodes.CC_Base_Body_1.geometry}
                    material={nodes.CC_Base_Body_1.material}
                    skeleton={nodes.CC_Base_Body_1.skeleton}
                    morphTargetDictionary={
                      nodes.CC_Base_Body_1.morphTargetDictionary
                    }
                    morphTargetInfluences={
                      nodes.CC_Base_Body_1.morphTargetInfluences
                    }
                  />
                  <skinnedMesh
                    name="CC_Base_Body_2"
                    geometry={nodes.CC_Base_Body_2.geometry}
                    material={materials.Std_Skin_Body}
                    skeleton={nodes.CC_Base_Body_2.skeleton}
                    morphTargetDictionary={
                      nodes.CC_Base_Body_2.morphTargetDictionary
                    }
                    morphTargetInfluences={
                      nodes.CC_Base_Body_2.morphTargetInfluences
                    }
                  />
                  <skinnedMesh
                    name="CC_Base_Body_3"
                    geometry={nodes.CC_Base_Body_3.geometry}
                    material={materials.Std_Skin_Arm}
                    skeleton={nodes.CC_Base_Body_3.skeleton}
                    morphTargetDictionary={
                      nodes.CC_Base_Body_3.morphTargetDictionary
                    }
                    morphTargetInfluences={
                      nodes.CC_Base_Body_3.morphTargetInfluences
                    }
                  />
                  <skinnedMesh
                    name="CC_Base_Body_4"
                    geometry={nodes.CC_Base_Body_4.geometry}
                    material={materials.Std_Nails}
                    skeleton={nodes.CC_Base_Body_4.skeleton}
                    morphTargetDictionary={
                      nodes.CC_Base_Body_4.morphTargetDictionary
                    }
                    morphTargetInfluences={
                      nodes.CC_Base_Body_4.morphTargetInfluences
                    }
                  />
                  <skinnedMesh
                    name="CC_Base_Body_5"
                    geometry={nodes.CC_Base_Body_5.geometry}
                    material={materials.Std_Eyelash}
                    skeleton={nodes.CC_Base_Body_5.skeleton}
                    morphTargetDictionary={
                      nodes.CC_Base_Body_5.morphTargetDictionary
                    }
                    morphTargetInfluences={
                      nodes.CC_Base_Body_5.morphTargetInfluences
                    }
                  />
                </group>
                <group name="CC_Base_Eye">
                  <skinnedMesh
                    name="CC_Base_Eye_1"
                    geometry={nodes.CC_Base_Eye_1.geometry}
                    material={materials.Std_Eye_R}
                    skeleton={nodes.CC_Base_Eye_1.skeleton}
                    morphTargetDictionary={
                      nodes.CC_Base_Eye_1.morphTargetDictionary
                    }
                    morphTargetInfluences={
                      nodes.CC_Base_Eye_1.morphTargetInfluences
                    }
                  />
                  <skinnedMesh
                    name="CC_Base_Eye_2"
                    geometry={nodes.CC_Base_Eye_2.geometry}
                    material={materials.Std_Cornea_R}
                    skeleton={nodes.CC_Base_Eye_2.skeleton}
                    morphTargetDictionary={
                      nodes.CC_Base_Eye_2.morphTargetDictionary
                    }
                    morphTargetInfluences={
                      nodes.CC_Base_Eye_2.morphTargetInfluences
                    }
                  />
                  <skinnedMesh
                    name="CC_Base_Eye_3"
                    geometry={nodes.CC_Base_Eye_3.geometry}
                    material={materials.Std_Eye_L}
                    skeleton={nodes.CC_Base_Eye_3.skeleton}
                    morphTargetDictionary={
                      nodes.CC_Base_Eye_3.morphTargetDictionary
                    }
                    morphTargetInfluences={
                      nodes.CC_Base_Eye_3.morphTargetInfluences
                    }
                  />
                  <skinnedMesh
                    name="CC_Base_Eye_4"
                    geometry={nodes.CC_Base_Eye_4.geometry}
                    material={materials.Std_Cornea_L}
                    skeleton={nodes.CC_Base_Eye_4.skeleton}
                    morphTargetDictionary={
                      nodes.CC_Base_Eye_4.morphTargetDictionary
                    }
                    morphTargetInfluences={
                      nodes.CC_Base_Eye_4.morphTargetInfluences
                    }
                  />
                </group>
                <group name="CC_Base_EyeOcclusion">
                  <skinnedMesh
                    name="CC_Base_EyeOcclusion_1"
                    geometry={nodes.CC_Base_EyeOcclusion_1.geometry}
                    material={materials.Std_Eye_Occlusion_R}
                    skeleton={nodes.CC_Base_EyeOcclusion_1.skeleton}
                    morphTargetDictionary={
                      nodes.CC_Base_EyeOcclusion_1.morphTargetDictionary
                    }
                    morphTargetInfluences={
                      nodes.CC_Base_EyeOcclusion_1.morphTargetInfluences
                    }
                  />
                  <skinnedMesh
                    name="CC_Base_EyeOcclusion_2"
                    geometry={nodes.CC_Base_EyeOcclusion_2.geometry}
                    material={materials.Std_Eye_Occlusion_L}
                    skeleton={nodes.CC_Base_EyeOcclusion_2.skeleton}
                    morphTargetDictionary={
                      nodes.CC_Base_EyeOcclusion_2.morphTargetDictionary
                    }
                    morphTargetInfluences={
                      nodes.CC_Base_EyeOcclusion_2.morphTargetInfluences
                    }
                  />
                </group>
                <group name="CC_Base_TearLine">
                  <skinnedMesh
                    name="CC_Base_TearLine_1"
                    geometry={nodes.CC_Base_TearLine_1.geometry}
                    material={materials.Std_Tearline_R}
                    skeleton={nodes.CC_Base_TearLine_1.skeleton}
                    morphTargetDictionary={
                      nodes.CC_Base_TearLine_1.morphTargetDictionary
                    }
                    morphTargetInfluences={
                      nodes.CC_Base_TearLine_1.morphTargetInfluences
                    }
                  />
                  <skinnedMesh
                    name="CC_Base_TearLine_2"
                    geometry={nodes.CC_Base_TearLine_2.geometry}
                    material={materials.Std_Tearline_L}
                    skeleton={nodes.CC_Base_TearLine_2.skeleton}
                    morphTargetDictionary={
                      nodes.CC_Base_TearLine_2.morphTargetDictionary
                    }
                    morphTargetInfluences={
                      nodes.CC_Base_TearLine_2.morphTargetInfluences
                    }
                  />
                </group>
                <group name="CC_Base_Teeth">
                  <skinnedMesh
                    name="CC_Base_Teeth_1"
                    geometry={nodes.CC_Base_Teeth_1.geometry}
                    material={materials.Std_Upper_Teeth}
                    skeleton={nodes.CC_Base_Teeth_1.skeleton}
                    morphTargetDictionary={
                      nodes.CC_Base_Teeth_1.morphTargetDictionary
                    }
                    morphTargetInfluences={
                      nodes.CC_Base_Teeth_1.morphTargetInfluences
                    }
                  />
                  <skinnedMesh
                    name="CC_Base_Teeth_2"
                    geometry={nodes.CC_Base_Teeth_2.geometry}
                    material={materials.Std_Lower_Teeth}
                    skeleton={nodes.CC_Base_Teeth_2.skeleton}
                    morphTargetDictionary={
                      nodes.CC_Base_Teeth_2.morphTargetDictionary
                    }
                    morphTargetInfluences={
                      nodes.CC_Base_Teeth_2.morphTargetInfluences
                    }
                  />
                </group>
                <group name="Classic_short">
                  <skinnedMesh
                    name="Classic_short_1"
                    geometry={nodes.Classic_short_1.geometry}
                    material={nodes.Classic_short_1.material}
                    skeleton={nodes.Classic_short_1.skeleton}
                    morphTargetDictionary={
                      nodes.Classic_short_1.morphTargetDictionary
                    }
                    morphTargetInfluences={
                      nodes.Classic_short_1.morphTargetInfluences
                    }
                  />
                </group>
                <skinnedMesh
                  name="CC_Base_Tongue"
                  geometry={nodes.CC_Base_Tongue.geometry}
                  material={materials.Std_Tongue}
                  skeleton={nodes.CC_Base_Tongue.skeleton}
                  morphTargetDictionary={
                    nodes.CC_Base_Tongue.morphTargetDictionary
                  }
                  morphTargetInfluences={
                    nodes.CC_Base_Tongue.morphTargetInfluences
                  }
                />
                <skinnedMesh
                  name="Business_Shirt"
                  geometry={nodes.Business_Shirt.geometry}
                  material={nodes.Business_Shirt.material}
                  skeleton={nodes.Business_Shirt.skeleton}
                />
                <skinnedMesh
                  name="Business_Suit"
                  geometry={nodes.Business_Suit.geometry}
                  material={nodes.Business_Suit.material}
                  skeleton={nodes.Business_Suit.skeleton}
                />
                <skinnedMesh
                  name="Pants"
                  geometry={nodes.Pants.geometry}
                  material={nodes.Pants.material}
                  skeleton={nodes.Pants.skeleton}
                />
                <skinnedMesh
                  name="Short_cowboy_boots"
                  geometry={nodes.Short_cowboy_boots.geometry}
                  material={materials.Short_cowboy_boots}
                  skeleton={nodes.Short_cowboy_boots.skeleton}
                />
                <skinnedMesh
                  name="svgMesh1"
                  geometry={nodes.svgMesh1.geometry}
                  material={materials._}
                  skeleton={nodes.svgMesh1.skeleton}
                />
              </group>
              <group name="Armature" scale={0.01}>
                <group name="Backdrop_01" rotation={[-Math.PI / 2, 0, 0]}>
                  <group name="CC_Base_Pivot" position={[0, 63.171, 2.891]}>
                    {/* <mesh
                      name="Backdrop_01_1"
                      geometry={nodes.Backdrop_01_1.geometry}
                      material={materials.Backdrop_01}
                      position={[0, 0, 0.172]}
                      rotation={[Math.PI, 0, 0]}
                      scale={[0.29, 1, 1]}
                    /> */}
                  </group>
                </group>
              </group>
            </group>
            {/* <directionalLight
              intensity={5}
              decay={2}
              position={[5, 10, 7.5]}
              target={nodes.DirectionalLight.target}
            >
              <primitive
                object={nodes.DirectionalLight.target}
                position={[0, 0, -1]}
              />
            </directionalLight> */}
          </group>
        </group>
      </group>
    </group>
  );
}

useGLTF.preload('models/Parsian_Character.glb');
